import React from 'react';
import { Container, Divider, Card, Grid, Image } from 'semantic-ui-react';

const Home = () => (
    <div>
    <br />
    <br />
    <br />
    <br />
        <Container textAlign='left'>
            <h1>katie lay</h1>
        <br />
        </Container>
        <Container>
        <Grid>
            <Grid.Column width={2}>
            </Grid.Column>
            <Grid.Column width={6}>
            <p>
                I'm a junior web developer living in Salt Lake City. I've been working primarily with Ruby, Ruby on Rails, JavaScript, and ReactJS. I have just completed a Professional Development Web Developer program with DevPoint Labs, sponsored by the University of Utah. 
            </p>
            <p>
                I have worked for the last five years in online education as a Content Manager. I focused on website maintenance, content creation, project management, and curriculum development. I hope to transition into project management in the web development field.
            </p>
            </Grid.Column>
            <Grid.Column width={2}>
                
            </Grid.Column>
            <Grid.Column width={4}>
                <Image src='/images/headshot.jpg' />
            </Grid.Column>
        </Grid>
        </Container>
        <br />

    <Divider />
        <Container textAlign='center'>
            <h2>recent projects and accomplishments</h2>
            <br />
            </Container>
        <Grid stackable centered columns={4}
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ maxHeight: '50vh' }}
        >
        {/* want columns to center responsively */}
        <Grid item xs={4}>
        <Card.Group>
        <Card
            link
            href='http://www.jolleysriver.com'
            header="Jolley's River Shuttle"
            target='_blank'
            meta='JavaScript'
            description={[
            "Company site. Jolley's River Shuttle operates on Desolation Canyon, shuttling cars for river rafters.",
            ].join('')}
            />
            <Card
            link
            href='https://www.devpointlabs.com/university-of-utah-coding-bootcamp-full-time'
            target='_blank'
            header='DevPoint Labs'
            meta='Web Development Course'
            description={[
            'Eleven week full-time web development program sponsored by the University of Utah. The course freatures advanced curriculum focusing on Ruby on Rails, Javascript, ReactJS, Git, DB, and more. Completed February 2020.',
            ].join('')}
            />

            <Card
            link
            href='https://github.com/katie311/react-flashcards' 
            target='_blank'
            header='Flashcards'
            meta='JS and React'
            description={[
            'DevPoint Labs assignment: flashcard app I used to help study terminology.',
            ].join('')}
            />
        </Card.Group>
        </Grid>
        </Grid>
    <br />
    <br />
    <br />
</div>
);

export default Home;