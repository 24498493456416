import React from 'react';
import { Container, } from 'semantic-ui-react';
import ContactForm from './ContactForm';

const Contact = () => (
    <div>
    <br />
    <br />
    <br />
    <br />
    <Container textAlign='center'>
        <h1>get in touch</h1>
    <br />
    <br />
        <p>
            Contact me to collaborate, ask questions about my work, or simply to say hi!
        </p>
    <br />
    </Container>
    <ContactForm />
    <br />
    <br />
    </div>
);

export default Contact;