import React from 'react';
import { Menu, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const Navbar = () => (
    <Menu fixed='top' size='large' color='violet' 
    // color='violet' inverted
    >
        <Link to='/'>
            <Menu.Item>
                <img src='images/logo.png' alt='kl logo'/>
            </Menu.Item>
        </Link>
        <Link to='/about'>
            <Menu.Item>
                about
            </Menu.Item>
        </Link>
        <Link to='/portfolio'>
            <Menu.Item>
                portfolio
            </Menu.Item>
        </Link>
        <Link to='/contact'>
            <Menu.Item>
                contact
            </Menu.Item>
        </Link>
        {/* use icons from semantic */}
        <Menu.Menu position='right'>
        <a href='https://github.com/katie311' target='_blank' rel='noopener noreferrer'>
                <Menu.Item>
                    <Icon 
                        name='github'
                        size='large' />
                </Menu.Item>
            </a>
            <a href='https://www.linkedin.com/in/katielay' target='_blank' rel='noopener noreferrer'>
                <Menu.Item>
                    <Icon
                        name='linkedin'
                        size='large' />
                </Menu.Item>
            </a>
            <a href='https://www.instagram.com/katie_311/' target='_blank' rel='noopener noreferrer'>
                <Menu.Item>
                    <Icon
                        name='instagram'
                        size='large' />
                </Menu.Item>
            </a>
            <a href='https://www.facebook.com/katie.lay.311' target='_blank' rel='noopener noreferrer'>
                <Menu.Item>
                    <Icon 
                        name='facebook official'
                        size='large' />
                </Menu.Item>
            </a>
            <a href='https://twitter.com/SlinkygirlKate' target='_blank' rel='noopener noreferrer'>
                <Menu.Item>
                    <Icon
                        name='twitter'
                        size='large' />
                </Menu.Item>
            </a>
          </Menu.Menu>
    </Menu>
//     <div class="ui bottom fixed menu">
//     <Container>
//       <img src="/images/logo.png" />
//     </Container>
//     <a class="item">Features</a>
//     <a class="item">Testimonials</a>
//     <a class="item">Sign-in</a>
//   </div>
);

export default Navbar;