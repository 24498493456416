import React from 'react';
import { Image, Container, Segment, List, } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const Footer = () => (
    <div>
        <Segment>
        <Container textAlign='center'>
            <Link>
                <Image centered size='tiny' src='images/logo.png'  />
                <List horizontal inverted divided link size='small'>
                    <p>
                        2020
                    </p>
                </List>
            </Link>
        </Container>
    </Segment>
</div>
);

export default Footer;

