import React from "react";
import { Form, Container, Grid, Button } from 'semantic-ui-react';

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: ""
    };
  }

  render() {
    const { status } = this.state;
    return (
    <Container textAlign='center'>
    <Grid stackable centered columns >
    <Grid.Column width={8}>
      <Form
        onSubmit={this.submitForm}
        action="https://formspree.io/xpzqbajq"
        method="POST"
      >
        <Form.Field>
            <label>First Name:</label>
            <input type='text' name='first name' placeholder='first name' /> 
        </Form.Field>
        <Form.Field>
            <label>Last Name:</label>
            <input type='text' name='last name' placeholder='last name'/>
        </Form.Field>
        <Form.Field>
            <label>Email:</label>
            <input type="email" name="email" placeholder='email'/>
        </Form.Field>
        <Form.TextArea
            label='Your Message'
            name="message"
            placeholder='Type your message here...' />
        {status === "SUCCESS" ? <p>Thanks!</p> : <Button basic color='violet' content='Violet'>Submit</Button>}
        {status === "ERROR" && <p>Ooops! There was an error.</p>}
      </Form>
      </Grid.Column>
      </Grid>
      </Container>
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}