import React from 'react';
import { Container, Divider, Grid, Image, Card, Icon } from 'semantic-ui-react';

const Portfolio = () => (
    <div>
    <br />
    <br />
    <br />
    <br />
    <Container textAlign='left'>
        <h1>portfolio</h1>
    <br />
    </Container>
    <Container textAlign='justified'>
      <h3>Web Development</h3>
      <Divider />
      <p>
       I began working on websites in 2015. Starting with Wordpress, HTML, and CSS, I discovered I had an affinity for it. As my job responsibilities grew, I began to self-teach to expand my abilities. While I enjoyed learning on my own, I felt the structure provided in a bootcamp setting would accelerate my progress. In three months at DevPoint Labs, I've acquired a basic understanding of JavaScript, React, Ruby, and Ruby on Rails. I've also gained valuable skills in problem solving and learning techniques.
      </p>
      <p>
        I look forward to continuing to learn on my own. I want to get stronger in UX/UI and and back-end development. Eventually I hope to take my skills and transition into Project Management.
      </p>
    </Container>

    <br />
    <br />
    <Container textAlign='center'>
        <h3>tools and languages I use</h3>
    <br />
    </Container>
    <Container>
    <Grid 
        relaxed columns={6} 
        centered
    >
    <Grid.Column>
      <Image size='tiny' src='/images/vscode.png' />
    </Grid.Column>
    <Grid.Column>
      <Image size='tiny' src='/images/github.png' />
    </Grid.Column>
    <Grid.Column>
      <Image size='tiny' src='/images/ruby.jpeg' />
    </Grid.Column>
    <Grid.Column>
      <Image size='tiny' src='/images/react.png' />
    </Grid.Column>
    <Grid.Column>
      <Image size='tiny' src='/images/javascript.png' />
    </Grid.Column>
    <Grid.Column>
      <Image size='tiny' src='/images/postgresql.png' />
    </Grid.Column>
    <Grid.Column>
      <Image size='tiny' src='/images/hubspot.png' />
    </Grid.Column>
    <Grid.Column>
      <Image size='tiny' src='/images/wordpress.png' />
    </Grid.Column>
    <Grid.Column>
      <Image size='tiny' src='/images/mailchimp.png' />
    </Grid.Column>
    <Grid.Column>
      <Image size='tiny' src='/images/photoshop.jpeg' />
    </Grid.Column>
  </Grid>
  </Container>
  <br />
  <br />
  <br />
  <Container textAlign='center'>
            <h3>projects</h3>
            <br />
        <Grid stackable centered columns={4}
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ maxHeight: '50vh' }}
        >
        {/* want columns to center responsively */}
        <Grid item xs={4}>
        <Card.Group centered>
        <Card
            link
            href='http://www.jolleysriver.com'
            header="Jolley's River Shuttle"
            meta='JavaScript'
            description={[
            "Company site. Jolley's River Shuttle operates on Desolation Canyon, shuttling cars for river rafters.",
            ].join('')}
            />
        <Card
            link
            href='https://github.com/thesoxbox/roadtrip.git'
            target='_blank'
            header='Roadtrip Planner'
            meta='Ruby on Rails, React'
            description={[
            "This was a Hackathon challenge I participated in with 3 fellow DevPoint students. This app helps the user plan a road trip.",
            ].join('')}
          />

          <Card
            link
            href='https://github.com/katie311/AppointmentMaster'
            target='_blank'
            header='AppointmentMaster'
            meta='Ruby on Rails, JavaScript'
            description={[
            "DevPoint Labs assignment utilizing Devise to authenticate a user. Functions as an administrative application for a doctor's office",
            ].join('')}
          /> 
            <Card
            link
            header='BandFan'
            meta='Concert Tracker App'
            description={[
            "Simple app intended to help users keep track of concerts they've attended.",
            ].join('')}
            />

            <Card
            link
            href='https://github.com/katie311/trollo_project_management'
            target='_blank'
            header='Trollo Project Management'
            meta='Ruby, SQL'
            description={[
            "DevPoint Labs assignment designed to help users keep track of projects and their associated tasks.",
            ].join('')}
            />

            <Card
            link
            href='https://github.com/katie311/department-store-v2'
            target='_blank'
            header='Department Store'
            meta='Ruby on Rails, React'
            description={[
            "DevPoint Labs assignment featuring a department store. Multiple models and full CRUD functionality.",
            ].join('')}
            />
        </Card.Group>
        </Grid>
        </Grid>
    </Container>
    <br />
    <br />
    <br />
    <br />
  <Container textAlign='justified'>
      <h3>Work History and Passion Projects</h3>
      <Divider />
      <h4>
        <Icon name='briefcase' size='large' color='purple' />
        Content Manager—Educational Advantage School of Music
      </h4>
      <p>
      Educational Advantage School of Music is an accredited online high school that offers online music lessons for high school credit. I'm excited to work as the Content Manager, focusing on building our courses in our learning management system and assisting with content creation and revision. I also maintain and create content on our website, and our social media channels, as well as the sites of our sister companies: Educational Advantage and Granger School of Music. I'm passionate about using technology to bring music education to students nationwide.
      </p>
      <br />
      <h4>
        <Icon name='heart' size='large' color='purple' />
        Program Director—The Wesley Bell Ringers
      </h4>
      <p>The Wesley Bell Ringers are a youth handbell choir sponsored by Christ United Methodist Church in Salt Lake City, Utah. As Director, my responsibilities include managing rehearsals, choosing repertoire, planning and booking tours and concerts, and directing parent volunteers in their management of fundraisers, events, and the finances of the choir.</p>
        
      <p>Members of The Wesley Bell Ringers include teenagers from 9th grade through their first year of college. While in the choir, ringers learn important lessons in team work, personal responsibility, music theory and group performance. The Wesley Bell Ringers go on a two week tour of a different area of the United States every June. They perform in multiple cities over those two weeks, as well as visit many national landmarks and attractions. During our performances, I present lectures to our audiences on the history of our organization, the instruments and equipment that we use, bell ringing techniques and handbell music.</p>

      <p>This has been my Passion Project for the last five years. As a teenager, I participated in the program—as an adult I jumped at the chance to offer the program to future Ringers!
      </p>
      <br />
      <h4>
        <Icon name='headphones' size='large' color='purple' />
        Production Assistant—Mountain Town Music
        </h4>
      <p>

    {/* Management:
Set up and manage Mountain Town booth at concerts
Personally handle artist and crew hospitality
Responsible for the sale of Mountain Town merchandise and concessions as well as artist merchandise
Assist the Community Conductor (Director) with all of his duties and work overflow
Marketing:
Create the weekly e-newsletter
Update website calendar with artist bios and location/time information
Customer Relations:
Cultivate new members, and cater to existing members
Working with a team to develop a website network of our musicians, including providing assistance to artists in developing Electronic Press Kits */}
      </p>
    </Container>
    <br />
    <br />
    </div>
);

export default Portfolio;