import React from 'react';
import { Container, Divider, List, Image } from 'semantic-ui-react';

const About = () => (
    <div>
        <br />
        <br />
        <br />
        <br />
        <Container textAlign='left'><h1>about me</h1>
        <br />
        </Container>
        <Container>
      <p>
        Paragraphs to showcase soft skills.
      </p>
    </Container>
      <Divider />
    <br />
    <br />
    <Container textAlign='center'><h1>fun facts about me</h1>
        <br />
    <List>
    <List.Item>
      <List.Header>Food Fanatic</List.Header>
      I love checking out local restaurants in SLC and cooking at home!
    </List.Item>
    <br />
    <List.Item>
      <List.Header>Travel Fiend</List.Header>
      Travel is one of my greatest passions. My goal is to visit every continent, and as many countries as I can!
    </List.Item>
    <br />
    <List.Item>
      <List.Header>Musician</List.Header>
      I sing, and play the piano and saxophone. I also direct a youth handbell choir called The Wesley Bell Ringers. We perform on a two week concert tour every year.
    </List.Item>
    <br />
    <List.Item>
      <List.Header>Pet Lover</List.Header>
        I have an all-black german shepherd dog named Nox, and a matching black long-haired cat named Bagheera.
    </List.Item>
    <br />
  </List>
    </Container>
    <Container>
      <Image centered src='images/pets.jpg' size='large' />
    </Container>
    <br />
    <br />
  </div>
);

export default About;