import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import Navbar from './components/Navbar';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Home from './components/Home';
import Portfolio from './components/Portfolio';
import NoMatch from './components/NoMatch';
import './App.css';

const App = () => (
  <>
  <Navbar />
    <Container>
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/about' component={About} />
        <Route exact path='/portfolio' component={Portfolio} />
        <Route exact path='/contact' component={Contact} />
        <Route component={NoMatch} />
      </Switch>
    </Container>
    <Footer />
  </>
  );

export default App;
